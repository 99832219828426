.holders {
    @extend .products;
    position: relative;

    .row {
        transition: all .250s ease-in-out;
        height: fit-content;
    }

    #expand-holders-data {
        display: none;
    }

    .expand-btn {
        transition: all .250s ease-in-out;
    }

    #expand-holders-data:checked ~ .row {
        height: 84px !important;
        overflow: hidden;
    
            .expand-btn {
                transform: rotateZ(-90deg)
            }
        }

    .expand-btn {
        position: absolute;
        height: fit-content;
        width: fit-content;
        right: 24px;
        top: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;

        span {
            color: $primary;
            font-size: 32px;
        }
    }

    #expand-dependent-data {
        display: none;
    }

    .expand-dependent-btn {
        transition: all .250s ease-in-out;
    }

    #expand-dependent-data:checked ~ .row {
        height: 84px !important;
        overflow: hidden;
    
            .expand-dependent-btn {
                transform: rotateZ(-90deg)
            }
        }

    .expand-dependent-btn {
        position: absolute;
        height: fit-content;
        width: fit-content;
        right: 24px;
        top: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;

        span {
            color: $primary;
            font-size: 32px;
        }
    }


}

@import './colors.scss';


label {
    width: 100%;
    P {
        color: $text-dark;
        margin-bottom: 12px;
        font-size: 0.68rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    input {
        width: 100%;
        height: 40px;
        border: 1px solid #e9e8e8;
        outline: none;
        border-radius: 6px;
        padding: 0px 12px;
    }

    
}

.breadcrumb {
    margin-top: 24px;

    .home {
        display: flex;
        align-items: center;
        margin-right: 8px;

        svg {
            width: 20px;
            stroke: $primary;
        }

        p {
            margin-left: 8px;
            color: $primary;
        }
    }
}


  .btn-icon-primary {
    border: none;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    background-color: $primary;
    outline: none;
    display: flex;
    align-items: center;
    width: fit-content;

    span {
        margin-left: 8px;
        color: white;
    }
  }

  .btn-icon-primary-inactive {
    border: none;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    background-color: $primary-inactive;
    outline: none;
    display: flex;
    align-items: center;
    cursor: none;
    width: fit-content;

    span {
        margin-left: 8px;
        color: white;
    }
  }

  .btn-icon-danger {
    border: none;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    background-color: $danger;
    outline: none;
    display: flex;
    align-items: center;
    width: fit-content;

    span {
        margin-left: 8px;
        color: white;
    }
  }

  .btn-icon-danger-inactive {
    border: none;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    background-color: $background-danger;
    outline: none;
    display: flex;
    align-items: center;
    cursor: none;
    width: fit-content;

    span {
        margin-left: 8px;
        color: white;
    }
  }

  .btn-icon-secondary {
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    cursor: pointer;
    color: $primary;
    background-color: transparent;
    outline: none;
    display: flex;
    align-items: center;
    width: fit-content;

    span {
        margin-left: 8px;
        color: $primary;
    }
  }

  .footer {
      display: flex;
      justify-content: flex-end;
      button {
          margin-left: 8px;
      }
  }

.basic-input {
    label {
        width: 100%;
        p {
            color: #5e5873 !important;
            margin-bottom: 12px;
            font-size: 0.68rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
    
        input {
            background-color: transparent;
            background-clip: padding-box;
            width: 100%;
            height: 40px;
            border: 1px solid #d8d6de;
            outline: none;
            border-radius: 6px;
            padding: 0px 12px;
            transition: all .250s ease-in-out;
            color: #6e6b7b;
            font-weight: 300;
        }

        textarea {
            background-color: transparent;
            background-clip: padding-box;
            width: 100%;
            min-height: 200px;
            border: 1px solid #d8d6de;
            outline: none;
            border-radius: 6px;
            padding: 16px 12px;
            transition: all .250s ease-in-out;
            color: #6e6b7b;
            font-weight: 300; 
        }

        select {
            background-color: transparent;
            background-clip: padding-box;
            width: 100%;
            height: 40px;
            border: 1px solid #d8d6de;
            outline: none;
            border-radius: 6px;
            padding: 0px 12px;
            transition: all .250s ease-in-out;
            color: #6e6b7b;
            font-weight: 300;
            -webkit-appearance: none;
            appearance: none;
            
        }

        .select-wrapper {
            position: relative;

        }

        .select-wrapper::after {
            content: "▼";
            font-size: 10px;
            top: 16px;
            color: $primary;
            right: 10px;
            position: absolute;
        }

        .readonly {
            background-color: #efefef;
        }
    
        input:focus {
        background-color: #fff;
        border-color: $primary;
        color: #6e6b7b;
        outline: 0;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }

        .input-helper {
            color: #b9b9c3 !important;
            font-size: 12px;
            font-weight: 390;
        }

        .input-error {
            color: $danger !important;
            font-size: 12px;
            font-weight: 390;
        }
        
    }
}

.price-input {
    @extend .basic-input;

    position: relative;
    .flag {
        position: absolute;
        top: 33px;
        left: 8px;
        background-color: $primary;
        font-size: 12px;
        color: white;
        border-radius: 4px;
        padding: 4px;
    }

    input {
        padding-left: 42px !important; 
        text-align: start;
    }
}

.add-input {
    @extend .basic-input;
    position: relative;

    .add-btn {
        position: absolute;
        top: 29px;
        right: 8px;
        background-color: $primary;
        font-size: 12px;
        color: white;
        border-radius: 4px;
        padding: 4px;
        border: none;
        
        .material-icons-round {
            color: white;
            font-size: 18px;
        }
    }


}

.toast-success-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $success;
    color: white;
    height: 5vh;
    width: 30vw;
    border-radius: 4px; 
    opacity: 0;
    animation: toast-animation 4s ease-in-out;
    z-index: 3000;


    span {
        color: white;
        margin-left: 8px;
    }
    .toast-success {
        margin: 0px;
        padding: 0px;
        border-radius: 4px;
        font-weight: 300;
    }
}


.toast-error-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $danger;
    color: white;
    height: 5vh;
    width: 30vw;
    border-radius: 4px; 
    opacity: 0;
    animation: toast-animation 4s ease-in-out;
    z-index: 3000;


    span {
        color: white;
        margin-left: 8px;
    }
    .toast-error {
        margin: 0px;
        padding: 0px;
        border-radius: 4px;
        font-weight: 300;
    }
}

@keyframes  toast-animation {
    0% {
        opacity: 0;
        top: -64px;
    }

    10% {
        top: 0;
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.card-bg {
    -webkit-box-shadow: -2px 0px 14px 4px #ececec; 
     box-shadow: -2px 0px 14px 4px #ececec;
     border-radius: 6px;
     background-color: white;
}

.card-padding-right {
    padding-right: 8px !important;
}

.card-padding-left {
    padding-left: 8px !important;
}

.table-wrapper {

    .table-actions {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        justify-content: space-between;

        .extended-input {
            input {
                min-width: 300px !important;
            }
        }

        button {
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            max-height: 32px;
            span {
                margin-right: 8px;
                color: white;
                font-size: 16px;
            }
        }

        label {
            p {
                font-size: 8px;
            }
            input {
                text-align: end;
                max-height: 28px;
                max-width: 100px;
                font-size: 11px;
                border-radius: 0px;
            }
        }

        .basic-input {
            select {
                text-align: center;
                max-height: 28px;
                max-width: 100px;
                font-size: 11px;
                border-radius: 0px;
            }

            .select-wrapper::after {
                content: "▼";
                font-size: 10px;
                top: 10px;
                color: $primary;
                right: 24px;
                position: absolute;
            }
        }
        

        .table-btn-accent {
            background-color: $accent;
            border: none;
            padding: 6px 16px;
            border-radius: 4px;
            color: white;
        }

        .table-btn-primary{
            background-color: $primary;
            border: none;
            padding: 6px 16px;
            border-radius: 4px;
            color: white;
        }

        .table-btn-danger{
            background-color: $danger;
            border: none;
            padding: 6px 16px;
            border-radius: 4px;
            color: white;
        }
    }

    .table-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        select {
            max-width: 42px;
            text-align: center;
            max-height: 24px;
            margin-right: 8px;
            border: 1px solid $primary;
            border-radius: 4px;
            margin: 0px 4px;
        }

        strong {
            font-weight: bold;
        }

        button {
            margin: 0px 8px;
            border-radius: 4px;
            background-color: $primary;
            color: white;
            border: none;
            padding: 4px 8px;
        }

        span {
            font-weight: bold;
        }
    }

    table {
        th, tr, td {
            padding: 1.2rem 1rem 1rem 0px !important;
        }
    
        th {
            color: #181C32 !important;
            font-size: 14px;
        }
    
        td {
            color: $text-dark3;
            border-top: 1px dashed #e5e5e5;
            font-size: 14px;
            vertical-align: middle;

            .bg-danger {
                color: $danger;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $background-danger !important;
                border-radius: 4px;
                padding: 4px 16px;
            }

            .bg-success {
                color: $success;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $background-success !important;
                border-radius: 4px;
                padding: 4px 16px;

            }

            .bg-other {
                color: white;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #6200EE !important;
                border-radius: 4px;
                padding: 4px 16px;

            }

            .bg-alert {
                color: $primary;
                width: fit-content;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 136, 255, 0.16) !important;
                border-radius: 4px;
                padding: 4px 16px;
            }

            .table-action-icon {
                display: flex;
                height: fit-content;
                align-items: center;
                margin-left: 8px;
                position: relative;

                button {
                    background-color: transparent;
                    border: none;
                }

                span {
                    color: $primary;
                }
            }
        }
    
    }
}


.custom-switcher-primary {
    width: 54px;
    height: 24px;
    position: relative;

    label {
        cursor: pointer;
        height: 22px;
        width: 54px;
        border-radius: 100px;
        position: relative;
        .floating-btn {
            background-color: $danger;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 10px;
            margin: auto;
            border-radius: 12px;
            transition: all .250s ease-in-out;
        }
        
        .slider {
            background-color: $background-danger;
            border: 2px solid $danger;
            width: 50px;
            height: 22px;
            border-radius: 100px;

        }

        .input-switcher:checked ~ .floating-btn {
            transform: translateX(24px);
            background-color: $primary;
        }

        .input-switcher:checked ~ .slider {
            background-color: $background-light;
            border: 2px solid $primary;
        }


    }

    
}

.my-modal {
    position: fixed;
    z-index: 110000000;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    justify-content: center;

    .my-modal-box {
        padding: 0px;
        min-width: 400px;
        width: fit-content;
        max-width: 100vw;
        background-color: white;
        min-height: 300px;
        border-radius: 20px;
        opacity: 1;
        animation: modal-fade-in .450s ease-in-out;
        display: flex;
        flex-direction: column;
        border: 4px solid #e5e5e5;
    }

    .my-modal-title {
        text-align: center;
        padding: 24px;
        font-weight: 600;
        border-bottom: 1px solid #c9c9c9;
        color: $text-dark;
    }

    .my-modal-body {
        max-height: 80vh;
        overflow-y: scroll;
        display: flex;
        padding: 24px;
        flex: 1;
        line-height: 27px;
        font-size: 15px;
        align-items: center;
        justify-content: center;
    }

    .my-modal-footer-primary {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #c9c9c9;
        
        button {
            background-color: transparent;
            border: none;
            outline: none;        
            color: $primary
        }
    }

    .my-modal-footer-secondary {
        @extend .my-modal-footer-primary;
        button {
            color: $accent;
        }
    }
    .my-modal-footer-danger {
        @extend .my-modal-footer-primary;
        button {
            color: $danger;
        }
    }
    .my-modal-footer-success {
        @extend .my-modal-footer-primary;
        button {
            color: $success;
        }
    }
    .my-modal-footer-alert {
        @extend .my-modal-footer-primary;
        button {
            color: orange;
        }
    }
    
}

@keyframes modal-fade-in {
    from {
        transform: translateY(-100px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.not-exsits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;

    span {
        font-size: 64px;
        color: #c5c5c5;
    }
    h3 {
        text-align: center;
        padding: 24px 0px;
        color: #c5c5c5;
        font-size: 22px;
    }

    button {
        margin-top: 24px;
        span {
            color: white;
            font-size: 24px;
        }
    }
}


.modal-api-error {
    width: 100%;
}

.allow-modifications {
    display: flex;
    color: #5e5873; 

    input {
        margin-right: 8px;
    }
}

.info-section-container {
    display: flex;
    justify-content: flex-end;

    .info-wrapper {
        display: flex;
        margin-left: 16px;

        :nth-child(1) {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            color: $text-dark2;
        }

        :nth-child(2) {
            font-size: 12px;
            margin-left: 4px;
            text-transform: uppercase;
            color: $primary;
        }
    }
}

#map {
    height: 70vh !important;
    width: 100vw !important;
}

.btn-card-small-danger {
   button {
    @extend .btn-icon-danger;
    height: 32px !important;
    background-color: transparent;
    color: $danger;
    border: 1px solid $danger;

    font-size: 10px;
    font-weight: 600;

    span {
        color: $danger;
    }
   }
}

.text-danger {
    color: $danger !important;
}

.text-primary {
    color: $primary !important;
}

.text-dark {
    color: $text-dark !important;
}

.appends {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .append-wrapper {
        margin-bottom: 8px;
    }
    p {
        font-weight: bold;
        color: $text-dark;
        margin-bottom: 24px;
    }

    a {
        color: $primary;
    }

    button {
        background-color: transparent;
        border: 1px solid $danger;
        border-radius: 100px;
        margin-left: 24px;
        margin-bottom: 12px;
    }

    span {
        color: $primary;
        text-decoration: underline;
    }
}

.header-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-active {
        background-color: $primary;
        color: white;
        border-radius: 100px;
        padding: 8px 0px;
        width: 100px;
        margin: 0px 8px 0px 8px;
        border: 1px solid $primary;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
    }

    .btn-inactive {
        background-color: white;
        color: $primary;
        border-radius: 100px;
        padding: 8px 0px;
        width: 100px;
        margin: 0px 8px 0px 8px;
        border: 1px solid $primary;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
    }
}

.add-remove-list {
    button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }
    }
}


.payment-status {
    display: flex;
    align-items: flex-start;

    h1 {
        margin: 0px;
        padding: 0px;
        margin-right: 24px;
    }

    .bg-danger {
        color: $danger;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-danger !important;
        border-radius: 4px;
        padding: 4px 16px;
    }

    .bg-success {
        color: $success;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-success !important;
        border-radius: 4px;
        padding: 4px 16px;

    }

    .bg-alert {
        color: $primary;
        width: fit-content;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 136, 255, 0.16) !important;
        border-radius: 4px;
        padding: 4px 16px;
    }
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        .btn-active {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $primary;
            color: $primary;
            padding: 4px 8px;
            border-radius: 2px;
            width: fit-content;
            margin-top: 12px;
        }

        .btn-inactive {
            background-color: transparent;
            border: none;
            color: 'black';
            padding: 4px 8px;
            border-radius: 2px;
            width: fit-content;
            margin-top: 12px;
        }
    }
    .card-background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        position: relative;

        .card-letters-wrapper {
            position: absolute;
            display: flex;
            width: 88%;
            height: 50%;
            bottom: 36px;
            justify-content: space-evenly;

            p {
                margin: 0px;
                padding: 0px;
                color: #f1f1f1;
                font-family: 'card-font';
                text-transform: uppercase;
                font-size: 14px;
            }

            .card-letters-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .card-letters-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .card-letters-title {
                font-size: 9px;
                letter-spacing: 1.2px;
                margin-bottom: 4px;
            }
        }
    
        img {
            width: 100%;
        }
}

.download-card-btn {
    background-color: $primary;
    border: none;
    color: white;
    padding: 4px 8px;
    border-radius: 2px;
    width: fit-content;
    margin-top: 12px;
    
}

.download-card-btn-secondary {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    padding: 4px 8px;
    border-radius: 2px;
    width: fit-content;
    margin-top: 12px;
    
}


}

[role=tooltip],
.hidetooltip.hidetooltip.hidetooltip + [role=tooltip] {
  visibility: hidden;
  position: absolute;
  top: 2rem;
  right: 0px;
  background: #62EE;
  width: fit-content;
  color: white;
  width: 400px;
  z-index: 999;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  p {
      padding: 16px;
      text-align: center;
  }
}
[aria-describedby]:hover,
[aria-describedby]:focus {
  position: relative;
}
[aria-describedby]:hover + [role=tooltip],
[aria-describedby]:focus + [role=tooltip] {
 visibility: visible;
}

.dashboard-btn {
    background-color: $primary;
    border: none;
    padding: 8px 32px;
    border-radius: 4px;
    font-size: 14px;
    color: white;

}

.nodataoverlay {
    color: #cfcfcf;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    position: absolute;
    padding: 24px
}

.filter-wrapper {

    .activate {
        display: flex;

        p {
            margin-left: 8px;
            color: $accent;
            font-weight: 600;
        }
    }

    .filter-date-input-wrapper {
        padding: 8px 0px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        display: flex;

        input {
            
        }
    }

    // .filter-date-input-wrapper::after {
    //     content: "▼";
    //     font-size: 10px;
    //     top: 16px;
    //     color: $primary;
    //     top: 78px;
    //     right: 32px !important;
    //     position: absolute;
    // }
}

.divisor-1 {
    margin: 0px 32px;
    font-weight: 500;
    color: #A6B0C3;
}

.fetched-values-group {
    display: flex;
    justify-content: center;

    .income-fetched-value-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 14px;
            margin-right: 4px;
            height: fit-content;
        }
    
        p {
            font-weight: bold;
            font-size: 11px;
            color: rgb(88, 102, 126);
            span {
                color: $primary
            }
        }
    }
}

.installment-option {
    select {
        min-width: 200px !important;
    }
}

.btn-all {
    width: fit-content;
    font-size: 8px;
    padding: 4px 8px;
    background-color: $primary;
    border: none;
    color: #f5f5f5;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-top: 8px;
    border-radius: 2px;
    height: 30px;
    text-transform: uppercase;
}


.show-professional-modal {
    
    h5 {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 24px 0px 8px 0px;
    }

    .title-wrapper {
        .avatar {
            img {

            }
        }

        h1 {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            margin: 0px 0px 8px 0px;
        }

        .especialization {
            text-align: center;
        }

        .council-register {
            margin-top: 8px;
            text-align: center;
            font-size: 12px;
        }
    }

    .accredited-wrapper {


        margin-top: 24px;
        .accredited-link {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        .accredited-item {
            display: flex;
            justify-content: space-between;
            border-radius: 4px;
            padding: 24px 8px;
            box-shadow: 0px .5px 4px #bbbbbb;
            margin-bottom: 12px;

            .accredited-title {
                margin: 0px;
                padding: 0px;
                display: flex;
                align-items: center;
                font-size: 12px;
                text-transform: uppercase;
                color: #6200EE;
                margin-right: 24px;
            }

            button {
                background-color: transparent;
                border: none;

                span {
                    color: $primary;
                }
            }
        }
    }




}

.btn-professional {
    border-radius: 100px;
    background-color: #6200EE;
    color: white;
    border: none;
    font-size: 12px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-top: 8px;
    box-shadow: 0px 1px 5px #5e5873;
}

.generate-excel-btn-wrapper {
    background-color: $primary;
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 12px;
    min-width: 300px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    box-shadow: 1px 1px 5px #696969;
    display: flex;
    align-items: center;

    span {
        margin-right: 8px;
        color: white;
    }
}


.logo-partners-table {
    width: 48px;
    height: 24px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #A6B0C3;
    object-fit: contain;
}

.modal-report {
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }

    .subtitle {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        color: $primary;
        margin-top: 24px
    }

    .header-btn-wrapper {
        margin-top: 24px;

        button {
            padding: 4px 16px;
            margin: 0px 8px;
            border-radius: 100px;
        }
        .active {
            background-color: rgb(196, 221, 245);
            border: none;
            color: $primary;
        }

        .inactive {
            border: 1px solid rgb(244, 246, 248);
            color: #696969;

        }
    }

    .content {
        .item-container {
            margin: 24px 0px;
            padding: 8px;
            box-shadow: 0px 0px 5px 5px #f5f5f5;
            border-radius: 4px;
            .item-wrapper {
                margin: 24px 0px;
                width: 100%;
                .item-description {
                    font-weight: bold;
                    margin-bottom: 8px;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #696969 !important;

                    span {
                        color: black;
                    }
                }
                .slider-wrapper {
                    background-color: #f0f0f0;
                    height: 6px;
                    width: 100%;
                    border-radius: 100px;
                    position: relative;
                    .slider {
                        transition: all ease-in-out .400s;
                        position: absolute;
                        background-color: #6200EE;
                        height: 6px;
                        width: 50%;
                        border-radius: 100px;
                    }
                 }
                }
            }
        }

}

.slider-content-wrapper {

    position: relative;
    // background-color: #666666;
    display: block;
    min-height: 400px;
    transition: all ease-in-out 450ms;
    width: 100%;

    .div1 {
        // background-color: #6200EE;
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 400px;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .div2 {
        // background-color: rgb(89, 184, 89);
        width: 100%;
        height: 100%;
        min-height: 400px;
        position: absolute;
        left: 100%;
        top: 0px;
    }

    .div3 {
        // background-color: orange;
        width: 100%;
        height: fit-content;
        min-height: 400px;
        position: absolute;
        left: 200%;
        top: 0px;
    }
}


.borderless-icon-btn {
    background-color: transparent;
    outline: none;
    color: $primary;
    border: none;
    width: fit-content;
    padding: 0px;

    span {
        color: $primary;
        width: fit-content;
        margin-right: 8px;
    }
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .divider-vertical {
        width: 1px;
        height: 24px;
        margin: 0px 4px;
        background-color: #6200EE;
    }
    .title-wrapper {
        :nth-child(1) {
            color: #696969;
            font-weight: 600;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 200px;
        }

        :nth-child(2) {
            color: #212121;
            font-weight: 600;
            font-size: 10px;
            margin-top: 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 200px;
        }
    }
}


.borderless-icon-btn-danger {
    background-color: transparent;
    outline: none;
    color: $primary;
    border: none;
    width: fit-content;
    padding: 0px;

    span {
        color: $danger;
        width: fit-content;
        margin-right: 8px;
    }
}

.horizontal-divider {
    height: 2px;
    flex: 1;
    margin-left: 8px;
    background-color: #6200EE;
}

.pag-azul-info-container {
    margin-bottom: 24px;
    .pag-azul-info-wrapper {
        display: flex;
        .pag-azul-item-title {
            font-weight: bold;
            margin-right: 8px;
            font-size: 9px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .pag-azul-item-description {
            margin-right: 8px;
            font-size: 9px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
    }
}


.loadingwithfulllabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;

    .label {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .loading-wrapper {
       margin-top: 24px;
       width: 50%;
        .loading-track-container {
            width: 100%;
            position: relative;
            height: 4px;
            .loading-tracker-bg {
                width: 100%;
                height: 4px;
                position: absolute;
                background-color: #bda1e4;
            }
    
            .loading-tracker {
                width: 0%;
                position: absolute;
                background-color: #6200EE;
                height: 4px;
                z-index: 999;
                transition: all ease-in-out .450s;
            }
        }
    }


}

.payment-client-link {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none !important;
    margin-left: 8px;
}

.partners-modal-wrapper {

    button {
        padding: 4px;
        display: flex;
    }

    .partner-active {
        background-color: transparent;
        border: 1px solid $primary;
        color: $primary;
        border: 1px solid $primary;
        margin: 4px 0px;

        }
    .partner-inactive {
        background-color: transparent;
        border: none;
        }
}

.controller-wrapper {
    button {
        background-color: transparent;
        border: none;
        span {
            color: #6200EE;
        }
    }
}

.ticket-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .ticket-medio-container {
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
        max-width: 200px;
        max-height: 100px;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        :nth-child(1) {
            color: white;
            font-size: 10px;
            text-transform: uppercase;
        }

        :nth-child(2) {
            margin-top: 4px;
            color: #eeca00;
            font-size: 14px;
            font-weight: bold;
        }

    }

    .ticket-medio-container-active {
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 200px;
        max-height: 100px;
        justify-content: center;
        background-color: #6200EE;
        :nth-child(1) {
            color: white;
            font-size: 10px;
            text-transform: uppercase;
        }

        :nth-child(2) {
            margin-top: 4px;
            color: #00ee9f;
            font-size: 20px;
            font-weight: bold;
        }

    }
}

.large-option {

    .select-wrapper::after {
        content: "▼";
        font-size: 10px;
        top: 16px;
        color: $primary;
        right: 4px !important;
        position: absolute;
    }
}

.link-red {
    text-decoration: line-through !important;
    color: red !important;
    a {
        text-decoration: none !important;
        color: red;
    }
}

.link-orange {
    text-decoration: line-through !important;
    color: orange !important;
    a {
        text-decoration: none !important;
        color: orange;
    }
}

.td-block-perma {
    color: red !important;;
}

.td-block-temp {
    color: orange !important ;
}

.td-unblock {
    color: green !important;
}

.card-container-gateway-prizes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 56px 56px;
    width: fit-content;
    border-radius: 8px;
    margin: 0px 4px;
    box-shadow: 0.5px 0.5px 1px 2px #f5f5f5;

    .modal-toggler {
        background-color: $primary;
        border: none;
        padding: 4px 9px;
        color: white;
        border-radius: 2px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 12px;
        font-weight: bold;
    }
}

.ticket-container {
    background-color: #fefeff;
    width: 120px;
    height: 120px;
    display: flex;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 8px;

    :nth-child(1) {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 4px;
 
    }

    :nth-child(2) {
        font-size: 18px;
        font-weight: bold;
        color: #212121;
    }

}

.open-new-tab {
    background-color: $primary;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
}

.text-number-alert {
    font-weight: 600;
    font-size: 72px !important;
    color: $alert !important;
    width: fit-content;
    text-align: center;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
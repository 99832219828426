.loading {
    position: fixed;
    z-index: 100000;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    
    .spinner-wrapper {
        padding: 32px;
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: spinner-container .450s ease-in-out;
        opacity: 1;


        p {
            margin-top: 24px;
        }
    }


}

@keyframes  spinner-container {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
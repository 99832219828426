@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

label {
  width: 100%;
}
label P {
  color: #181C32;
  margin-bottom: 12px;
  font-size: 0.68rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
label input {
  width: 100%;
  height: 40px;
  border: 1px solid #e9e8e8;
  outline: none;
  border-radius: 6px;
  padding: 0px 12px;
}

.breadcrumb {
  margin-top: 24px;
}
.breadcrumb .home {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.breadcrumb .home svg {
  width: 20px;
  stroke: #039EF7;
}
.breadcrumb .home p {
  margin-left: 8px;
  color: #039EF7;
}

.btn-icon-primary {
  border: none;
  border-radius: 4px;
  padding: 8px 18px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  background-color: #039EF7;
  outline: none;
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn-icon-primary span {
  margin-left: 8px;
  color: white;
}

.btn-icon-primary-inactive {
  border: none;
  border-radius: 4px;
  padding: 8px 18px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  background-color: rgba(3, 158, 247, 0.5411764706);
  outline: none;
  display: flex;
  align-items: center;
  cursor: none;
  width: fit-content;
}
.btn-icon-primary-inactive span {
  margin-left: 8px;
  color: white;
}

.btn-icon-danger, .btn-card-small-danger button {
  border: none;
  border-radius: 4px;
  padding: 8px 18px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  background-color: #F1416D;
  outline: none;
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn-icon-danger span, .btn-card-small-danger button span {
  margin-left: 8px;
  color: white;
}

.btn-icon-danger-inactive {
  border: none;
  border-radius: 4px;
  padding: 8px 18px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  background-color: #F7D9E3;
  outline: none;
  display: flex;
  align-items: center;
  cursor: none;
  width: fit-content;
}
.btn-icon-danger-inactive span {
  margin-left: 8px;
  color: white;
}

.btn-icon-secondary {
  border: 1px solid #039EF7;
  border-radius: 4px;
  padding: 8px 18px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
  color: #039EF7;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn-icon-secondary span {
  margin-left: 8px;
  color: #039EF7;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
.footer button {
  margin-left: 8px;
}

.basic-input label, .add-input label, .price-input label {
  width: 100%;
}
.basic-input label p, .add-input label p, .price-input label p {
  color: #5e5873 !important;
  margin-bottom: 12px;
  font-size: 0.68rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.basic-input label input, .add-input label input, .price-input label input {
  background-color: transparent;
  background-clip: padding-box;
  width: 100%;
  height: 40px;
  border: 1px solid #d8d6de;
  outline: none;
  border-radius: 6px;
  padding: 0px 12px;
  transition: all 0.25s ease-in-out;
  color: #6e6b7b;
  font-weight: 300;
}
.basic-input label textarea, .add-input label textarea, .price-input label textarea {
  background-color: transparent;
  background-clip: padding-box;
  width: 100%;
  min-height: 200px;
  border: 1px solid #d8d6de;
  outline: none;
  border-radius: 6px;
  padding: 16px 12px;
  transition: all 0.25s ease-in-out;
  color: #6e6b7b;
  font-weight: 300;
}
.basic-input label select, .add-input label select, .price-input label select {
  background-color: transparent;
  background-clip: padding-box;
  width: 100%;
  height: 40px;
  border: 1px solid #d8d6de;
  outline: none;
  border-radius: 6px;
  padding: 0px 12px;
  transition: all 0.25s ease-in-out;
  color: #6e6b7b;
  font-weight: 300;
  -webkit-appearance: none;
  appearance: none;
}
.basic-input label .select-wrapper, .add-input label .select-wrapper, .price-input label .select-wrapper {
  position: relative;
}
.basic-input label .select-wrapper::after, .add-input label .select-wrapper::after, .price-input label .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 16px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.basic-input label .readonly, .add-input label .readonly, .price-input label .readonly {
  background-color: #efefef;
}
.basic-input label input:focus, .add-input label input:focus, .price-input label input:focus {
  background-color: #fff;
  border-color: #039EF7;
  color: #6e6b7b;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.basic-input label .input-helper, .add-input label .input-helper, .price-input label .input-helper {
  color: #b9b9c3 !important;
  font-size: 12px;
  font-weight: 390;
}
.basic-input label .input-error, .add-input label .input-error, .price-input label .input-error {
  color: #F1416D !important;
  font-size: 12px;
  font-weight: 390;
}

.price-input {
  position: relative;
}
.price-input .flag {
  position: absolute;
  top: 33px;
  left: 8px;
  background-color: #039EF7;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding: 4px;
}
.price-input input {
  padding-left: 42px !important;
  text-align: start;
}

.add-input {
  position: relative;
}
.add-input .add-btn {
  position: absolute;
  top: 29px;
  right: 8px;
  background-color: #039EF7;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding: 4px;
  border: none;
}
.add-input .add-btn .material-icons-round {
  color: white;
  font-size: 18px;
}

.toast-success-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #50CD89;
  color: white;
  height: 5vh;
  width: 30vw;
  border-radius: 4px;
  opacity: 0;
  animation: toast-animation 4s ease-in-out;
  z-index: 3000;
}
.toast-success-container span {
  color: white;
  margin-left: 8px;
}
.toast-success-container .toast-success {
  margin: 0px;
  padding: 0px;
  border-radius: 4px;
  font-weight: 300;
}

.toast-error-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1416D;
  color: white;
  height: 5vh;
  width: 30vw;
  border-radius: 4px;
  opacity: 0;
  animation: toast-animation 4s ease-in-out;
  z-index: 3000;
}
.toast-error-container span {
  color: white;
  margin-left: 8px;
}
.toast-error-container .toast-error {
  margin: 0px;
  padding: 0px;
  border-radius: 4px;
  font-weight: 300;
}

@keyframes toast-animation {
  0% {
    opacity: 0;
    top: -64px;
  }
  10% {
    top: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.card-bg, .dashboard .desactivated-user, .dashboard .defaulter, .dashboard .contract-reports-cards, .dashboard .card-contract-activities-primary, .dashboard .card-contract-activities, .dashboard .card-finance-expense, .dashboard .card-finance-income {
  -webkit-box-shadow: -2px 0px 14px 4px #ececec;
  box-shadow: -2px 0px 14px 4px #ececec;
  border-radius: 6px;
  background-color: white;
}

.card-padding-right {
  padding-right: 8px !important;
}

.card-padding-left {
  padding-left: 8px !important;
}

.table-wrapper .table-actions {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}
.table-wrapper .table-actions .extended-input input {
  min-width: 300px !important;
}
.table-wrapper .table-actions button {
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-height: 32px;
}
.table-wrapper .table-actions button span {
  margin-right: 8px;
  color: white;
  font-size: 16px;
}
.table-wrapper .table-actions label p {
  font-size: 8px;
}
.table-wrapper .table-actions label input {
  text-align: end;
  max-height: 28px;
  max-width: 100px;
  font-size: 11px;
  border-radius: 0px;
}
.table-wrapper .table-actions .basic-input select, .table-wrapper .table-actions .price-input select, .table-wrapper .table-actions .add-input select {
  text-align: center;
  max-height: 28px;
  max-width: 100px;
  font-size: 11px;
  border-radius: 0px;
}
.table-wrapper .table-actions .basic-input .select-wrapper::after, .table-wrapper .table-actions .price-input .select-wrapper::after, .table-wrapper .table-actions .add-input .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 10px;
  color: #039EF7;
  right: 24px;
  position: absolute;
}
.table-wrapper .table-actions .table-btn-accent {
  background-color: #7239EA;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
}
.table-wrapper .table-actions .table-btn-primary {
  background-color: #039EF7;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
}
.table-wrapper .table-actions .table-btn-danger {
  background-color: #F1416D;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
}
.table-wrapper .table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-wrapper .table-footer select {
  max-width: 42px;
  text-align: center;
  max-height: 24px;
  margin-right: 8px;
  border: 1px solid #039EF7;
  border-radius: 4px;
  margin: 0px 4px;
}
.table-wrapper .table-footer strong {
  font-weight: bold;
}
.table-wrapper .table-footer button {
  margin: 0px 8px;
  border-radius: 4px;
  background-color: #039EF7;
  color: white;
  border: none;
  padding: 4px 8px;
}
.table-wrapper .table-footer span {
  font-weight: bold;
}
.table-wrapper table th, .table-wrapper table tr, .table-wrapper table td {
  padding: 1.2rem 1rem 1rem 0px !important;
}
.table-wrapper table th {
  color: #181C32 !important;
  font-size: 14px;
}
.table-wrapper table td {
  color: #625f6e;
  border-top: 1px dashed #e5e5e5;
  font-size: 14px;
  vertical-align: middle;
}
.table-wrapper table td .bg-danger {
  color: #F1416D;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7D9E3 !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.table-wrapper table td .bg-success {
  color: #50CD89;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CCF0F4 !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.table-wrapper table td .bg-other {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6200EE !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.table-wrapper table td .bg-alert {
  color: #039EF7;
  width: fit-content;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 136, 255, 0.16) !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.table-wrapper table td .table-action-icon {
  display: flex;
  height: fit-content;
  align-items: center;
  margin-left: 8px;
  position: relative;
}
.table-wrapper table td .table-action-icon button {
  background-color: transparent;
  border: none;
}
.table-wrapper table td .table-action-icon span {
  color: #039EF7;
}

.custom-switcher-primary {
  width: 54px;
  height: 24px;
  position: relative;
}
.custom-switcher-primary label {
  cursor: pointer;
  height: 22px;
  width: 54px;
  border-radius: 100px;
  position: relative;
}
.custom-switcher-primary label .floating-btn {
  background-color: #F1416D;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 10px;
  margin: auto;
  border-radius: 12px;
  transition: all 0.25s ease-in-out;
}
.custom-switcher-primary label .slider {
  background-color: #F7D9E3;
  border: 2px solid #F1416D;
  width: 50px;
  height: 22px;
  border-radius: 100px;
}
.custom-switcher-primary label .input-switcher:checked ~ .floating-btn {
  transform: translateX(24px);
  background-color: #039EF7;
}
.custom-switcher-primary label .input-switcher:checked ~ .slider {
  background-color: #F5F8FA;
  border: 2px solid #039EF7;
}

.my-modal {
  position: fixed;
  z-index: 110000000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-modal .my-modal-box {
  padding: 0px;
  min-width: 400px;
  width: fit-content;
  max-width: 100vw;
  background-color: white;
  min-height: 300px;
  border-radius: 20px;
  opacity: 1;
  animation: modal-fade-in 0.45s ease-in-out;
  display: flex;
  flex-direction: column;
  border: 4px solid #e5e5e5;
}
.my-modal .my-modal-title {
  text-align: center;
  padding: 24px;
  font-weight: 600;
  border-bottom: 1px solid #c9c9c9;
  color: #181C32;
}
.my-modal .my-modal-body {
  max-height: 80vh;
  overflow-y: scroll;
  display: flex;
  padding: 24px;
  flex: 1;
  line-height: 27px;
  font-size: 15px;
  align-items: center;
  justify-content: center;
}
.my-modal .my-modal-footer-primary, .my-modal .my-modal-footer-alert, .my-modal .my-modal-footer-success, .my-modal .my-modal-footer-danger, .my-modal .my-modal-footer-secondary {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #c9c9c9;
}
.my-modal .my-modal-footer-primary button, .my-modal .my-modal-footer-alert button, .my-modal .my-modal-footer-success button, .my-modal .my-modal-footer-danger button, .my-modal .my-modal-footer-secondary button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #039EF7;
}
.my-modal .my-modal-footer-secondary button {
  color: #7239EA;
}
.my-modal .my-modal-footer-danger button {
  color: #F1416D;
}
.my-modal .my-modal-footer-success button {
  color: #50CD89;
}
.my-modal .my-modal-footer-alert button {
  color: orange;
}

@keyframes modal-fade-in {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.not-exsits {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}
.not-exsits span {
  font-size: 64px;
  color: #c5c5c5;
}
.not-exsits h3 {
  text-align: center;
  padding: 24px 0px;
  color: #c5c5c5;
  font-size: 22px;
}
.not-exsits button {
  margin-top: 24px;
}
.not-exsits button span {
  color: white;
  font-size: 24px;
}

.modal-api-error {
  width: 100%;
}

.allow-modifications {
  display: flex;
  color: #5e5873;
}
.allow-modifications input {
  margin-right: 8px;
}

.info-section-container {
  display: flex;
  justify-content: flex-end;
}
.info-section-container .info-wrapper {
  display: flex;
  margin-left: 16px;
}
.info-section-container .info-wrapper :nth-child(1) {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #5e5873;
}
.info-section-container .info-wrapper :nth-child(2) {
  font-size: 12px;
  margin-left: 4px;
  text-transform: uppercase;
  color: #039EF7;
}

#map {
  height: 70vh !important;
  width: 100vw !important;
}

.btn-card-small-danger button {
  height: 32px !important;
  background-color: transparent;
  color: #F1416D;
  border: 1px solid #F1416D;
  font-size: 10px;
  font-weight: 600;
}
.btn-card-small-danger button span {
  color: #F1416D;
}

.text-danger {
  color: #F1416D !important;
}

.text-primary {
  color: #039EF7 !important;
}

.text-dark {
  color: #181C32 !important;
}

.appends {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.appends .append-wrapper {
  margin-bottom: 8px;
}
.appends p {
  font-weight: bold;
  color: #181C32;
  margin-bottom: 24px;
}
.appends a {
  color: #039EF7;
}
.appends button {
  background-color: transparent;
  border: 1px solid #F1416D;
  border-radius: 100px;
  margin-left: 24px;
  margin-bottom: 12px;
}
.appends span {
  color: #039EF7;
  text-decoration: underline;
}

.header-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-btn-wrapper .btn-active {
  background-color: #039EF7;
  color: white;
  border-radius: 100px;
  padding: 8px 0px;
  width: 100px;
  margin: 0px 8px 0px 8px;
  border: 1px solid #039EF7;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
}
.header-btn-wrapper .btn-inactive {
  background-color: white;
  color: #039EF7;
  border-radius: 100px;
  padding: 8px 0px;
  width: 100px;
  margin: 0px 8px 0px 8px;
  border: 1px solid #039EF7;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
}

.add-remove-list button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.add-remove-list button p {
  margin: 0;
  padding: 0;
}

.payment-status {
  display: flex;
  align-items: flex-start;
}
.payment-status h1 {
  margin: 0px;
  padding: 0px;
  margin-right: 24px;
}
.payment-status .bg-danger {
  color: #F1416D;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7D9E3 !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.payment-status .bg-success {
  color: #50CD89;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CCF0F4 !important;
  border-radius: 4px;
  padding: 4px 16px;
}
.payment-status .bg-alert {
  color: #039EF7;
  width: fit-content;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 136, 255, 0.16) !important;
  border-radius: 4px;
  padding: 4px 16px;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-wrapper .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.card-wrapper .btn-wrapper .btn-active {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #039EF7;
  color: #039EF7;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}
.card-wrapper .btn-wrapper .btn-inactive {
  background-color: transparent;
  border: none;
  color: "black";
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}
.card-wrapper .card-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
}
.card-wrapper .card-background .card-letters-wrapper {
  position: absolute;
  display: flex;
  width: 88%;
  height: 50%;
  bottom: 36px;
  justify-content: space-evenly;
}
.card-wrapper .card-background .card-letters-wrapper p {
  margin: 0px;
  padding: 0px;
  color: #f1f1f1;
  font-family: "card-font";
  text-transform: uppercase;
  font-size: 14px;
}
.card-wrapper .card-background .card-letters-wrapper .card-letters-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-wrapper .card-background .card-letters-wrapper .card-letters-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-wrapper .card-background .card-letters-wrapper .card-letters-title {
  font-size: 9px;
  letter-spacing: 1.2px;
  margin-bottom: 4px;
}
.card-wrapper .card-background img {
  width: 100%;
}
.card-wrapper .download-card-btn {
  background-color: #039EF7;
  border: none;
  color: white;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}
.card-wrapper .download-card-btn-secondary {
  background-color: transparent;
  border: 1px solid #039EF7;
  color: #039EF7;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}

[role=tooltip],
.hidetooltip.hidetooltip.hidetooltip + [role=tooltip] {
  visibility: hidden;
  position: absolute;
  top: 2rem;
  right: 0px;
  background: rgba(102, 34, 238, 0.9333333333);
  width: fit-content;
  color: white;
  width: 400px;
  z-index: 999;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
[role=tooltip] p,
.hidetooltip.hidetooltip.hidetooltip + [role=tooltip] p {
  padding: 16px;
  text-align: center;
}

[aria-describedby]:hover,
[aria-describedby]:focus {
  position: relative;
}

[aria-describedby]:hover + [role=tooltip],
[aria-describedby]:focus + [role=tooltip] {
  visibility: visible;
}

.dashboard-btn {
  background-color: #039EF7;
  border: none;
  padding: 8px 32px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
}

.nodataoverlay {
  color: #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  position: absolute;
  padding: 24px;
}

.filter-wrapper .activate {
  display: flex;
}
.filter-wrapper .activate p {
  margin-left: 8px;
  color: #7239EA;
  font-weight: 600;
}
.filter-wrapper .filter-date-input-wrapper {
  padding: 8px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
}
.divisor-1 {
  margin: 0px 32px;
  font-weight: 500;
  color: #A6B0C3;
}

.fetched-values-group {
  display: flex;
  justify-content: center;
}
.fetched-values-group .income-fetched-value-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fetched-values-group .income-fetched-value-wrapper svg {
  width: 14px;
  margin-right: 4px;
  height: fit-content;
}
.fetched-values-group .income-fetched-value-wrapper p {
  font-weight: bold;
  font-size: 11px;
  color: rgb(88, 102, 126);
}
.fetched-values-group .income-fetched-value-wrapper p span {
  color: #039EF7;
}

.installment-option select {
  min-width: 200px !important;
}

.btn-all {
  width: fit-content;
  font-size: 8px;
  padding: 4px 8px;
  background-color: #039EF7;
  border: none;
  color: #f5f5f5;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-top: 8px;
  border-radius: 2px;
  height: 30px;
  text-transform: uppercase;
}

.show-professional-modal h5 {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 24px 0px 8px 0px;
}
.show-professional-modal .title-wrapper h1 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 0px 8px 0px;
}
.show-professional-modal .title-wrapper .especialization {
  text-align: center;
}
.show-professional-modal .title-wrapper .council-register {
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
}
.show-professional-modal .accredited-wrapper {
  margin-top: 24px;
}
.show-professional-modal .accredited-wrapper .accredited-link {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.show-professional-modal .accredited-wrapper .accredited-item {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 24px 8px;
  box-shadow: 0px 0.5px 4px #bbbbbb;
  margin-bottom: 12px;
}
.show-professional-modal .accredited-wrapper .accredited-item .accredited-title {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #6200EE;
  margin-right: 24px;
}
.show-professional-modal .accredited-wrapper .accredited-item button {
  background-color: transparent;
  border: none;
}
.show-professional-modal .accredited-wrapper .accredited-item button span {
  color: #039EF7;
}

.btn-professional {
  border-radius: 100px;
  background-color: #6200EE;
  color: white;
  border: none;
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-top: 8px;
  box-shadow: 0px 1px 5px #5e5873;
}

.generate-excel-btn-wrapper {
  background-color: #039EF7;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 12px;
  min-width: 300px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  box-shadow: 1px 1px 5px #696969;
  display: flex;
  align-items: center;
}
.generate-excel-btn-wrapper span {
  margin-right: 8px;
  color: white;
}

.logo-partners-table {
  width: 48px;
  height: 24px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #A6B0C3;
  object-fit: contain;
}

.modal-report .title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.modal-report .subtitle {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #039EF7;
  margin-top: 24px;
}
.modal-report .header-btn-wrapper {
  margin-top: 24px;
}
.modal-report .header-btn-wrapper button {
  padding: 4px 16px;
  margin: 0px 8px;
  border-radius: 100px;
}
.modal-report .header-btn-wrapper .active {
  background-color: rgb(196, 221, 245);
  border: none;
  color: #039EF7;
}
.modal-report .header-btn-wrapper .inactive {
  border: 1px solid rgb(244, 246, 248);
  color: #696969;
}
.modal-report .content .item-container {
  margin: 24px 0px;
  padding: 8px;
  box-shadow: 0px 0px 5px 5px #f5f5f5;
  border-radius: 4px;
}
.modal-report .content .item-container .item-wrapper {
  margin: 24px 0px;
  width: 100%;
}
.modal-report .content .item-container .item-wrapper .item-description {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
  color: #696969 !important;
}
.modal-report .content .item-container .item-wrapper .item-description span {
  color: black;
}
.modal-report .content .item-container .item-wrapper .slider-wrapper {
  background-color: #f0f0f0;
  height: 6px;
  width: 100%;
  border-radius: 100px;
  position: relative;
}
.modal-report .content .item-container .item-wrapper .slider-wrapper .slider {
  transition: all ease-in-out 0.4s;
  position: absolute;
  background-color: #6200EE;
  height: 6px;
  width: 50%;
  border-radius: 100px;
}

.slider-content-wrapper {
  position: relative;
  display: block;
  min-height: 400px;
  transition: all ease-in-out 450ms;
  width: 100%;
}
.slider-content-wrapper .div1 {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 400px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.slider-content-wrapper .div2 {
  width: 100%;
  height: 100%;
  min-height: 400px;
  position: absolute;
  left: 100%;
  top: 0px;
}
.slider-content-wrapper .div3 {
  width: 100%;
  height: fit-content;
  min-height: 400px;
  position: absolute;
  left: 200%;
  top: 0px;
}

.borderless-icon-btn {
  background-color: transparent;
  outline: none;
  color: #039EF7;
  border: none;
  width: fit-content;
  padding: 0px;
}
.borderless-icon-btn span {
  color: #039EF7;
  width: fit-content;
  margin-right: 8px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-container .divider-vertical {
  width: 1px;
  height: 24px;
  margin: 0px 4px;
  background-color: #6200EE;
}
.title-container .title-wrapper :nth-child(1) {
  color: #696969;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}
.title-container .title-wrapper :nth-child(2) {
  color: #212121;
  font-weight: 600;
  font-size: 10px;
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.borderless-icon-btn-danger {
  background-color: transparent;
  outline: none;
  color: #039EF7;
  border: none;
  width: fit-content;
  padding: 0px;
}
.borderless-icon-btn-danger span {
  color: #F1416D;
  width: fit-content;
  margin-right: 8px;
}

.horizontal-divider {
  height: 2px;
  flex: 1;
  margin-left: 8px;
  background-color: #6200EE;
}

.pag-azul-info-container {
  margin-bottom: 24px;
}
.pag-azul-info-container .pag-azul-info-wrapper {
  display: flex;
}
.pag-azul-info-container .pag-azul-info-wrapper .pag-azul-item-title {
  font-weight: bold;
  margin-right: 8px;
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.pag-azul-info-container .pag-azul-info-wrapper .pag-azul-item-description {
  margin-right: 8px;
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.loadingwithfulllabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
.loadingwithfulllabel .label {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.loadingwithfulllabel .loading-wrapper {
  margin-top: 24px;
  width: 50%;
}
.loadingwithfulllabel .loading-wrapper .loading-track-container {
  width: 100%;
  position: relative;
  height: 4px;
}
.loadingwithfulllabel .loading-wrapper .loading-track-container .loading-tracker-bg {
  width: 100%;
  height: 4px;
  position: absolute;
  background-color: #bda1e4;
}
.loadingwithfulllabel .loading-wrapper .loading-track-container .loading-tracker {
  width: 0%;
  position: absolute;
  background-color: #6200EE;
  height: 4px;
  z-index: 999;
  transition: all ease-in-out 0.45s;
}

.payment-client-link {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none !important;
  margin-left: 8px;
}

.partners-modal-wrapper button {
  padding: 4px;
  display: flex;
}
.partners-modal-wrapper .partner-active {
  background-color: transparent;
  border: 1px solid #039EF7;
  color: #039EF7;
  border: 1px solid #039EF7;
  margin: 4px 0px;
}
.partners-modal-wrapper .partner-inactive {
  background-color: transparent;
  border: none;
}

.controller-wrapper button {
  background-color: transparent;
  border: none;
}
.controller-wrapper button span {
  color: #6200EE;
}

.ticket-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
}
.ticket-wrapper .ticket-medio-container {
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  max-height: 100px;
  align-items: center;
  justify-content: center;
  background-color: #039EF7;
}
.ticket-wrapper .ticket-medio-container :nth-child(1) {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
}
.ticket-wrapper .ticket-medio-container :nth-child(2) {
  margin-top: 4px;
  color: #eeca00;
  font-size: 14px;
  font-weight: bold;
}
.ticket-wrapper .ticket-medio-container-active {
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  max-height: 100px;
  justify-content: center;
  background-color: #6200EE;
}
.ticket-wrapper .ticket-medio-container-active :nth-child(1) {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
}
.ticket-wrapper .ticket-medio-container-active :nth-child(2) {
  margin-top: 4px;
  color: #00ee9f;
  font-size: 20px;
  font-weight: bold;
}

.large-option .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 16px;
  color: #039EF7;
  right: 4px !important;
  position: absolute;
}

.link-red {
  text-decoration: line-through !important;
  color: red !important;
}
.link-red a {
  text-decoration: none !important;
  color: red;
}

.link-orange {
  text-decoration: line-through !important;
  color: orange !important;
}
.link-orange a {
  text-decoration: none !important;
  color: orange;
}

.td-block-perma {
  color: red !important;
}

.td-block-temp {
  color: orange !important;
}

.td-unblock {
  color: green !important;
}

.card-container-gateway-prizes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 56px 56px;
  width: fit-content;
  border-radius: 8px;
  margin: 0px 4px;
  box-shadow: 0.5px 0.5px 1px 2px #f5f5f5;
}
.card-container-gateway-prizes .modal-toggler {
  background-color: #039EF7;
  border: none;
  padding: 4px 9px;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 12px;
  font-weight: bold;
}

.ticket-container {
  background-color: #fefeff;
  width: 120px;
  height: 120px;
  display: flex;
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
}
.ticket-container :nth-child(1) {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 4px;
}
.ticket-container :nth-child(2) {
  font-size: 18px;
  font-weight: bold;
  color: #212121;
}

.open-new-tab {
  background-color: #039EF7;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
}

.text-number-alert {
  font-weight: 600;
  font-size: 72px !important;
  color: #FFC702 !important;
  width: fit-content;
  text-align: center;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .left-content {
  background-color: #7239EA;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login .right-content .form-wrapper {
  max-width: 400px;
  width: 100%;
}
.login .right-content .form-wrapper label {
  margin-bottom: 1.68rem;
  position: relative;
}
.login .right-content .form-wrapper i {
  position: absolute;
  right: 16px;
  top: 36px;
  z-index: 999;
  cursor: pointer;
}
.login .right-content .form-wrapper #login-password-input {
  padding-right: 54px;
}
.login .right-content .form-wrapper .forgot-password {
  text-align: end;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-size: 0.68rem;
  font-weight: 600;
  color: #252525;
}
.login .right-content .form-wrapper button {
  height: 40px;
  width: 100%;
  margin-top: 24px;
  background-color: #00c16e;
  border: none;
  color: white;
  border-radius: 6px;
}
.login .right-content .back-to-login i {
  color: #5390D9;
}
.login .right-content .back-to-login a {
  margin-left: 8px;
  color: #5390D9;
}

.internal-layout {
  min-height: 100vh;
  background-color: #f8f8f8;
  display: flex;
}
.internal-layout .right-container {
  position: relative !important;
  flex: 1;
  min-height: 100vh;
  margin: 20px 42px 0px 120px;
  transition: all 0.45s ease-in-out !important;
  padding-bottom: 64px;
}
.internal-layout .right-container .header {
  position: sticky;
  top: 0;
  left: 0;
  height: 62px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  z-index: 999;
}

.aside {
  height: 100vh;
  background-color: white;
  width: 80px;
  overflow-x: hidden;
  position: fixed;
  z-index: 999;
  transition: all 0.25s ease-in-out !important;
}
.aside .aside-logo-wrapper {
  margin-top: 24px;
  width: 42px;
  margin-left: 20px;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 24px;
}
.aside .menu-section {
  margin-bottom: 24px;
  margin-left: 24px;
  min-width: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.aside .menu-section span {
  color: #625f6e;
}
.aside .menu-section button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.aside .menu-section button p {
  margin: 0px;
  margin-left: 12px;
  color: #9899ac;
  visibility: hidden;
}
.aside .menu-section svg {
  color: #625f6e;
  width: 20px;
}
.aside .menu-section .sub-menu-invisible {
  transition: all 0.65s ease-in-out;
  display: none;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-top: 16px;
  margin-left: 8px;
  height: 0px;
}
.aside .menu-section .sub-menu-invisible .invisible-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}
.aside .menu-section .sub-menu-invisible .invisible-item button {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.2px;
  color: #5e5873;
}
.aside .menu-section .sub-menu-invisible .invisible-item span {
  margin-right: 8px;
  font-size: 12px;
  color: #5e5873;
}

.aside:hover {
  width: 260px;
}
.aside:hover .aside-logo-wrapper {
  margin-top: 24px;
  width: 42px;
  margin-left: 20px;
  justify-content: center;
  overflow: visible;
}
.aside:hover .menu-section button p {
  margin: 0px;
  margin-left: 12px;
  margin-top: 4px;
  color: #5e5873;
  visibility: visible;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.2px;
}

.aside:hover ~ .right-container {
  margin: 20px 42px 0px 300px;
}

.menu-section:hover .sub-menu-invisible {
  display: flex;
  flex-direction: column;
  visibility: visible;
  opacity: 1;
  display: flex;
  height: fit-content;
}

.header nav {
  padding: 0px 24px;
}
.header nav .menu-link {
  cursor: pointer;
  padding: 0px 32px;
}
.header nav .menu-link span {
  color: #6e6b7b;
}
.header nav .avatar-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.header nav .avatar-wrapper .online {
  position: absolute;
  bottom: 2px;
  right: 0px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: greenyellow;
  z-index: 999;
}
.header nav .avatar-wrapper p {
  color: #5e5873;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.header nav .avatar-wrapper span {
  color: #625f6e;
  font-weight: 300;
}
.header nav .avatar-wrapper .material-icons-round {
  color: #6e6b7b;
}
.header nav .avatar-wrapper img {
  margin-left: 16px;
  max-width: 40px;
  border-radius: 100px;
}
.header nav .avatar-wrapper .avatar-menu-hidden {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 56px;
  right: -24px;
  background-color: white;
  padding: 24px 32px;
  width: 130%;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
}
.header nav .avatar-wrapper .avatar-menu-hidden .avatar-menu-hidden-item-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12px 0px;
  cursor: pointer;
}
.header nav .avatar-wrapper .avatar-menu-hidden .avatar-menu-hidden-item-wrapper span {
  margin-right: 8px;
}
.header nav .avatar-wrapper:hover .avatar-menu-hidden {
  visibility: visible;
  opacity: 1;
}

.loading {
  position: fixed;
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .spinner-wrapper {
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: spinner-container 0.45s ease-in-out;
  opacity: 1;
}
.loading .spinner-wrapper p {
  margin-top: 24px;
}

@keyframes spinner-container {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.profile {
  background-color: white;
  padding: 64px;
  border-radius: 8px;
}
.profile h1 {
  margin-bottom: 24px;
  font-size: 22px;
  color: #7239EA;
}
.profile .avatar-wrapper {
  margin-top: 24px;
  display: flex;
}
.profile .avatar-wrapper button {
  background-color: transparent;
  border: none;
  color: #7239EA;
}
.profile .avatar-wrapper img {
  border-radius: 8px;
  max-width: 120px;
  max-height: 120px;
}
.profile .profile-input-wrapper {
  max-width: 400px;
  width: 100%;
}
.profile .profile-input-wrapper label {
  margin-top: 24px;
}

.config {
  padding: 64px;
}
.config section {
  background-color: #fbfbfb;
  padding: 32px;
  border-radius: 8px;
}
.config .allow-modifications {
  margin-bottom: 24px;
}
.config .allow-modifications input {
  margin-right: 8px;
}
.config h1 {
  font-size: 22px;
  margin-bottom: 24px;
  color: #181C32;
}
.config label {
  margin-bottom: 24px;
}

.users {
  min-height: 1px;
  padding-bottom: 64px;
}
.users .basic-input, .users .price-input, .users .add-input {
  max-width: 100px;
}
.users .basic-input P, .users .price-input P, .users .add-input P {
  display: none;
}
.users .basic-input select, .users .price-input select, .users .add-input select {
  height: 32px;
}
.users .basic-input .select-wrapper::after, .users .price-input .select-wrapper::after, .users .add-input .select-wrapper::after {
  content: "▼";
  font-size: 8px;
  top: 14px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.users .left-content {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px 21px;
}
.users .left-content h1 {
  font-size: 18px;
  margin-bottom: 32px;
}
.users .left-content table th, .users .left-content table tr, .users .left-content table td {
  padding: 1rem 1rem 1rem 0px;
}
.users .left-content table th {
  color: #181C32 !important;
}
.users .left-content table td {
  color: #625f6e;
  border-top: 1px dashed rgba(37, 37, 37, 0.1529411765);
}
.users .right-content {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px 21px;
}
.users .right-content h1 {
  font-size: 18px;
  margin-bottom: 32px;
}

.send-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0px 24px 64px 24px;
}
.send-email-container label p {
  text-align: start !important;
  padding: 0px;
  margin: 0px;
}
.send-email-container .big {
  font-size: 100px;
  color: #50CD89;
}
.send-email-container .text-error {
  color: #F1416D !important;
}
.send-email-container h1 {
  margin-bottom: 24px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #181C32;
}
.send-email-container p {
  line-height: 26px;
  padding: 0px 25%;
  text-align: justify;
  font-weight: 300;
}

.right-floating-aside-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 4000;
}
.right-floating-aside-container .dismiss-bg {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100vw;
  height: 100vh;
}
.right-floating-aside-container .right-content {
  background-color: white;
  height: 100vh;
  width: 30vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 40000;
  animation: right-content-slide 0.45s ease-in-out;
}
.right-floating-aside-container .right-content .close {
  position: absolute;
  top: 54px;
  left: 32px;
}
.right-floating-aside-container .right-content .close button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #039EF7;
  padding: 4px;
  border-radius: 4px;
  color: #039EF7;
}
.right-floating-aside-container .right-content .close button span {
  color: #039EF7;
}
.right-floating-aside-container .right-content .children-content {
  height: 100%;
  width: 100%;
}

@keyframes right-content-slide {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
.new-admin-form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
.new-admin-form h1 {
  margin-top: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.new-admin-form h3 {
  text-align: center;
  margin: 24px 0px;
  font-weight: 300;
}
.new-admin-form .error-text {
  color: #F1416D;
  background-color: #F7D9E3;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
}
.new-admin-form .footer {
  margin-top: 24px;
}
.new-admin-form label {
  margin: 8px 0px;
}

.users {
  min-height: 1px;
  padding-bottom: 64px;
}
.users .basic-input, .users .price-input, .users .add-input {
  max-width: 100px;
}
.users .basic-input P, .users .price-input P, .users .add-input P {
  display: none;
}
.users .basic-input select, .users .price-input select, .users .add-input select {
  height: 32px;
}
.users .basic-input .select-wrapper::after, .users .price-input .select-wrapper::after, .users .add-input .select-wrapper::after {
  content: "▼";
  font-size: 8px;
  top: 14px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.users .left-content {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px 21px;
}
.users .left-content h1 {
  font-size: 18px;
  margin-bottom: 32px;
}
.users .left-content table th, .users .left-content table tr, .users .left-content table td {
  padding: 1rem 1rem 1rem 0px;
}
.users .left-content table th {
  color: #181C32 !important;
}
.users .left-content table td {
  color: #625f6e;
  border-top: 1px dashed rgba(37, 37, 37, 0.1529411765);
}
.users .right-content {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 21px 21px;
}
.users .right-content h1 {
  font-size: 18px;
  margin-bottom: 32px;
}

.send-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0px 24px 64px 24px;
}
.send-email-container label p {
  text-align: start !important;
  padding: 0px;
  margin: 0px;
}
.send-email-container .big {
  font-size: 100px;
  color: #50CD89;
}
.send-email-container .text-error {
  color: #F1416D !important;
}
.send-email-container h1 {
  margin-bottom: 24px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #181C32;
}
.send-email-container p {
  line-height: 26px;
  padding: 0px 25%;
  text-align: justify;
  font-weight: 300;
}

.products, .dashboard, .partners, .holders, .contracts, .client, .new-sellers, .seller {
  padding-bottom: 64px;
}
.products section, .dashboard section, .partners section, .holders section, .contracts section, .client section, .new-sellers section, .seller section {
  padding: 32px;
  border-radius: 8px;
}
.products h1, .dashboard h1, .partners h1, .holders h1, .contracts h1, .client h1, .new-sellers h1, .seller h1 {
  font-size: 22px;
  margin-bottom: 24px;
  color: #181C32;
}
.products label, .dashboard label, .partners label, .holders label, .contracts label, .client label, .new-sellers label, .seller label {
  margin-bottom: 24px;
}
.products .benefits-wrapper, .dashboard .benefits-wrapper, .partners .benefits-wrapper, .holders .benefits-wrapper, .contracts .benefits-wrapper, .client .benefits-wrapper, .new-sellers .benefits-wrapper, .seller .benefits-wrapper {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
}
.products .benefits-wrapper button, .dashboard .benefits-wrapper button, .partners .benefits-wrapper button, .holders .benefits-wrapper button, .contracts .benefits-wrapper button, .client .benefits-wrapper button, .new-sellers .benefits-wrapper button, .seller .benefits-wrapper button {
  background-color: transparent;
  border-radius: 100px;
  padding: 4px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #039EF7;
  border: 1px solid #039EF7;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
}
.products .benefits-wrapper button span, .dashboard .benefits-wrapper button span, .partners .benefits-wrapper button span, .holders .benefits-wrapper button span, .contracts .benefits-wrapper button span, .client .benefits-wrapper button span, .new-sellers .benefits-wrapper button span, .seller .benefits-wrapper button span {
  font-size: 14px;
  color: #c4c4c4;
  margin-left: 4px;
}
.products .optionals p, .dashboard .optionals p, .partners .optionals p, .holders .optionals p, .contracts .optionals p, .client .optionals p, .new-sellers .optionals p, .seller .optionals p {
  color: #5e5873;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.2px;
}
.products .optionals .check-wrapper, .dashboard .optionals .check-wrapper, .partners .optionals .check-wrapper, .holders .optionals .check-wrapper, .contracts .optionals .check-wrapper, .client .optionals .check-wrapper, .new-sellers .optionals .check-wrapper, .seller .optionals .check-wrapper {
  margin-top: 18px;
  display: flex;
  align-items: center;
}
.products .optionals .check-wrapper p, .dashboard .optionals .check-wrapper p, .partners .optionals .check-wrapper p, .holders .optionals .check-wrapper p, .contracts .optionals .check-wrapper p, .client .optionals .check-wrapper p, .new-sellers .optionals .check-wrapper p, .seller .optionals .check-wrapper p {
  margin-left: 8px;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0;
  color: #181C32;
  font-weight: 400;
}

.client {
  position: relative;
  transition: all 0.25s ease-in-out;
}
.client .row {
  transition: all 0.25s ease-in-out;
  height: fit-content;
}
.client #expand-client-data {
  display: none;
}
.client #expand-holders-data {
  display: none;
}
.client .expand-btn {
  transition: all 0.25s ease-in-out;
}
.client .expand-holders-btn {
  transition: all 0.25s ease-in-out;
}
.client #expand-holders-data:checked ~ .row {
  height: 84px !important;
  overflow: hidden;
}
.client #expand-holders-data:checked ~ .row .expand-holders-btn {
  transform: rotateZ(-90deg);
}
.client .expand-holders-btn {
  position: absolute;
  height: fit-content;
  width: fit-content;
  right: 24px;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}
.client .expand-holders-btn span {
  color: #039EF7;
  font-size: 32px;
}
.client #expand-client-data:checked ~ .row {
  height: 84px !important;
  overflow: hidden;
}
.client #expand-client-data:checked ~ .row .expand-btn {
  transform: rotateZ(-90deg);
}
.client .expand-btn {
  position: absolute;
  height: fit-content;
  width: fit-content;
  right: 24px;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}
.client .expand-btn span {
  color: #039EF7;
  font-size: 32px;
}

.holders {
  position: relative;
}
.holders .row {
  transition: all 0.25s ease-in-out;
  height: fit-content;
}
.holders #expand-holders-data {
  display: none;
}
.holders .expand-btn {
  transition: all 0.25s ease-in-out;
}
.holders #expand-holders-data:checked ~ .row {
  height: 84px !important;
  overflow: hidden;
}
.holders #expand-holders-data:checked ~ .row .expand-btn {
  transform: rotateZ(-90deg);
}
.holders .expand-btn {
  position: absolute;
  height: fit-content;
  width: fit-content;
  right: 24px;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}
.holders .expand-btn span {
  color: #039EF7;
  font-size: 32px;
}
.holders #expand-dependent-data {
  display: none;
}
.holders .expand-dependent-btn {
  transition: all 0.25s ease-in-out;
}
.holders #expand-dependent-data:checked ~ .row {
  height: 84px !important;
  overflow: hidden;
}
.holders #expand-dependent-data:checked ~ .row .expand-dependent-btn {
  transform: rotateZ(-90deg);
}
.holders .expand-dependent-btn {
  position: absolute;
  height: fit-content;
  width: fit-content;
  right: 24px;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}
.holders .expand-dependent-btn span {
  color: #039EF7;
  font-size: 32px;
}

.partners .partner-photo-wrapper {
  width: 400px;
  height: 200px;
  background-color: #f5f5f5;
  margin-bottom: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners .partner-photo-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.partners .helper {
  font-size: 12px;
}

.dashboard .apexcharts-toolbar {
  display: none;
}
.dashboard .first-statistic {
  margin-right: 12px;
}
.dashboard .statistics .item-wrapper {
  display: flex;
  align-items: center;
}
.dashboard .statistics .item-wrapper .item-icon {
  margin-right: 8px;
}
.dashboard .statistics .item-wrapper .item-icon span {
  border-radius: 100px;
  padding: 8px;
  background-color: aqua;
}
.dashboard .statistics .item-wrapper .item-text span {
  font-size: 12px;
  margin-top: 16px;
}
.dashboard .total-contracts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.dashboard .total-contracts p {
  margin: 0px;
  padding: 0px;
}
.dashboard .total-contracts .title-wrapper {
  padding: 24px;
}
.dashboard .total-contracts .title-wrapper h5 {
  color: #5E5873;
}
.dashboard .total-contracts .title-wrapper p {
  margin-top: 8px;
  font-size: 12px;
  color: #6E6B7B;
}
.dashboard .total-contracts .medal-wrapper {
  position: absolute;
  right: 42px;
  top: 0px;
  height: fit-content;
}
.dashboard .total-contracts .medal-wrapper svg {
  width: 100%;
}
@media screen and (max-width: 1400px) {
  .dashboard .total-contracts .medal-wrapper svg {
    width: 50px;
    height: fit-content;
  }
}
.dashboard .total-contracts .btn-wrapper {
  padding: 24px;
}
.dashboard .total-contracts .btn-wrapper p {
  margin-bottom: 16px;
  color: #5E5873;
  font-size: 24px;
  font-weight: 600;
}
.dashboard .total-contracts .btn-wrapper span {
  font-size: 9px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
}
.dashboard .card-finance-income {
  background-color: white;
  width: 100%;
  height: fit-content;
  margin-top: 24px;
}
.dashboard .card-finance-income .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .card-finance-income .title-wrapper .basic-input select, .dashboard .card-finance-income .title-wrapper .price-input select, .dashboard .card-finance-income .title-wrapper .add-input select {
  border-radius: 0px;
  min-width: 80px;
  height: 28px !important;
  color: #039EF7;
  font-size: 10px;
  border: #039EF7 1px solid;
}
@media screen and (max-width: 1500px) {
  .dashboard .card-finance-income .title-wrapper .basic-input select, .dashboard .card-finance-income .title-wrapper .price-input select, .dashboard .card-finance-income .title-wrapper .add-input select {
    min-width: 60px;
    font-size: 8px;
  }
}
.dashboard .card-finance-income .title-wrapper .basic-input .select-wrapper::after, .dashboard .card-finance-income .title-wrapper .price-input .select-wrapper::after, .dashboard .card-finance-income .title-wrapper .add-input .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 10px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.dashboard .card-finance-income .title-wrapper .title {
  color: #5E5873;
}
.dashboard .card-finance-income .title-wrapper .subtitle {
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #6E6B7B;
  display: flex;
  align-items: center;
}
.dashboard .card-finance-income .title-wrapper .subtitle span {
  font-size: 14px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .card-finance-income .title-wrapper .subtitle .percent {
  font-size: 10px;
  font-weight: 300;
  margin: 0px;
  padding: 0px;
  margin-left: 2px;
}
.dashboard .card-finance-income .trend-wrapper {
  padding-top: 8px;
  background-color: #f3fcf3;
}
.dashboard .card-finance-income .chart-time-wrapper {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
}
.dashboard .card-finance-income .chart-time-wrapper .chart-time-inactive button {
  border: 1px solid #ada6a6;
  background-color: white;
  color: #ada6a6;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .card-finance-income .chart-time-wrapper .chart-time-active button {
  border: 1px solid #7367F0;
  background-color: rgb(238, 237, 253);
  color: #7367F0;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .card-finance-income .custom-chart tspan {
  display: none;
}
.dashboard .card-finance-expense {
  background-color: white;
  width: 100%;
  height: fit-content;
  margin-top: 24px;
}
.dashboard .card-finance-expense .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .card-finance-expense .title-wrapper .basic-input select, .dashboard .card-finance-expense .title-wrapper .price-input select, .dashboard .card-finance-expense .title-wrapper .add-input select {
  border-radius: 0px;
  width: 80px;
  height: 28px !important;
  color: #039EF7;
  font-size: 10px;
  border: #039EF7 1px solid;
}
.dashboard .card-finance-expense .title-wrapper .basic-input .select-wrapper::after, .dashboard .card-finance-expense .title-wrapper .price-input .select-wrapper::after, .dashboard .card-finance-expense .title-wrapper .add-input .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 10px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.dashboard .card-finance-expense .title-wrapper .title {
  color: #5E5873;
}
.dashboard .card-finance-expense .title-wrapper .subtitle {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #6E6B7B;
}
.dashboard .card-finance-expense .custom-chart tspan {
  display: none;
}
.dashboard .card-contract-activities {
  background-color: white;
  width: 100%;
  height: 100%;
  background-color: #7367F0;
  min-height: 261px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.dashboard .card-contract-activities .total-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 9px 16px;
  border-radius: 4px;
}
.dashboard .card-contract-activities .total-wrapper :nth-child(1) {
  text-transform: uppercase;
  margin-bottom: 4px;
  text-align: center;
  font-size: 10px;
  color: #6200EE;
}
.dashboard .card-contract-activities .total-wrapper :nth-child(2) {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #212121;
}
.dashboard .card-contract-activities .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .card-contract-activities .title-wrapper .title {
  color: white;
}
.dashboard .card-contract-activities .content-wrapper {
  padding: 0px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboard .card-contract-activities .content-wrapper button {
  background-color: transparent;
  border: none;
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
}
.dashboard .card-contract-activities .content-wrapper button p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.dashboard .card-contract-activities .content-wrapper button .track-wrapper {
  width: 100%;
  height: 12px;
  border-radius: 24px;
  background-color: #f5f5f5;
  margin-top: 8px;
}
.dashboard .card-contract-activities .content-wrapper button .track-wrapper .track-active {
  background-color: rgb(97, 212, 97);
  width: 0%;
  height: 12px;
  border-radius: 24px;
}
.dashboard .card-contract-activities .content-wrapper button .track-wrapper .track-inactive {
  background-color: #FAA527;
  width: 0%;
  height: 12px;
  border-radius: 24px;
}
.dashboard .card-contract-activities-primary {
  background-color: white;
  width: 100%;
  height: 100%;
  min-height: 261px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.dashboard .card-contract-activities-primary .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .card-contract-activities-primary .title-wrapper .title {
  color: #5E5873;
}
.dashboard .card-contract-activities-primary .content-wrapper {
  padding: 0px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard .card-contract-activities-primary .content-wrapper .text-number-alert {
  font-weight: 600;
  font-size: 72px;
  color: #FFC702;
  width: fit-content;
  text-align: center;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .card-contract-activities-primary .content-wrapper button {
  background-color: transparent;
  border: none;
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
}
.dashboard .card-contract-activities-primary .content-wrapper button p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.dashboard .card-contract-activities-primary .content-wrapper button .track-wrapper {
  width: 100%;
  height: 12px;
  border-radius: 24px;
  background-color: #f5f5f5;
  margin-top: 8px;
}
.dashboard .card-contract-activities-primary .content-wrapper button .track-wrapper .track-active {
  background-color: rgb(97, 212, 97);
  width: 50%;
  height: 12px;
  border-radius: 24px;
}
.dashboard .card-contract-activities-primary .content-wrapper button .track-wrapper .track-inactive {
  background-color: #FAA527;
  height: 12px;
  border-radius: 24px;
}
.dashboard .card-contract-activities-primary .content-wrapper .open-new-tab {
  background-color: #039EF7;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
}
.dashboard .contract-reports-cards {
  background-color: white;
  width: 100%;
  min-height: 350px;
  margin-top: 24px;
  border-radius: 6px;
}
.dashboard .contract-reports-cards .apexcharts-toolbar {
  display: none;
}
.dashboard .contract-reports-cards .allow-modifications {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.dashboard .contract-reports-cards .allow-modifications .active {
  height: 28px;
  margin-bottom: 12px;
  border: 1px solid #039EF7;
  background-color: #039EF7;
  color: white;
  font-size: 10px;
  padding: 4px 16px;
}
.dashboard .contract-reports-cards .allow-modifications .inactive {
  height: 28px;
  margin-bottom: 12px;
  border: 1px solid #039EF7;
  background-color: white;
  color: #039EF7;
  font-size: 10px;
  padding: 4px 16px;
}
.dashboard .contract-reports-cards .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .contract-reports-cards .title-wrapper .title {
  color: #5E5873;
}
.dashboard .contract-reports-cards .title-wrapper .basic-input select, .dashboard .contract-reports-cards .title-wrapper .price-input select, .dashboard .contract-reports-cards .title-wrapper .add-input select {
  border-radius: 0px;
  min-width: 80px;
  height: 28px !important;
  color: #039EF7;
  font-size: 10px;
  border: #039EF7 1px solid;
}
.dashboard .contract-reports-cards .title-wrapper .basic-input .select-wrapper::after, .dashboard .contract-reports-cards .title-wrapper .price-input .select-wrapper::after, .dashboard .contract-reports-cards .title-wrapper .add-input .select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 10px;
  color: #039EF7;
  right: 10px;
  position: absolute;
}
.dashboard .defaulter {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.dashboard .defaulter .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .defaulter .title-wrapper .title {
  color: #5E5873;
}
.dashboard .defaulter .dashboard-card-content {
  flex: 1;
}
.dashboard .defaulter .dashboard-card-footer {
  border-top: #e5e5e5 1px solid;
  padding: 18px;
  display: flex;
  justify-content: center;
}
.dashboard .defaulter .dashboard-card-footer button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #039EF7;
}
.dashboard .desactivated-user {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.dashboard .desactivated-user .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dashboard .desactivated-user .title-wrapper .title {
  color: #5E5873;
}
.dashboard .desactivated-user .dashboard-card-content {
  flex: 1;
}
.dashboard .desactivated-user .dashboard-card-footer {
  border-top: #e5e5e5 1px solid;
  padding: 18px;
  display: flex;
  justify-content: center;
}
.dashboard .desactivated-user .dashboard-card-footer button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #039EF7;
}

.card-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.material-icons-round {
  color: #6e6b7b;
}

@font-face {
  font-family: "card-font";
  src: url("../assets/fonts/nokio-slab-regular.otf");
}
body {
  background-color: white;
  font-family: "Inter", sans-serif;
}

a {
  cursor: pointer;
}


.new-admin-form {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;


    h1 {
        margin-top: 24px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
    }

    h3 {
        text-align: center;
        margin: 24px 0px;
        font-weight: 300;
    }

    .error-text {
        color: $danger;
        background-color: $background-danger;
        padding: 8px;
        border-radius: 4px;
        font-size: 12px;
    }

    .footer {
        margin-top: 24px;
    }
    label {
        margin: 8px 0px;
    }
}
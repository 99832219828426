.users {
    min-height:1px;
    padding-bottom: 64px;

    .basic-input {
        max-width: 100px;

        P {
            display: none;
        }
        
        select {
            height: 32px;
        }

        .select-wrapper::after {
            content: "▼";
            font-size: 8px;
            top: 14px;
            color: $primary;
            right: 10px;
            position: absolute;
        }
    }


    .left-content {
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 21px 21px;

        h1 {
            font-size: 18px;
            margin-bottom: 32px;
        }

        table {
            th, tr, td {
                padding: 1rem 1rem 1rem 0px;
            }

            th {
                color: #181C32 !important;
            }

            td {
                color: $text-dark3;
                border-top: 1px dashed $text-dark4;
            }


        }
    }

    .right-content {
        h1 {
            font-size: 18px;
            margin-bottom: 32px;
        }
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 21px 21px;

    }
}

.send-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0px 24px 64px 24px;

    label {
        p {
            text-align: start !important;
            padding: 0px;
            margin: 0px
        }
    }

    .big {
        font-size: 100px;
        color: $success;
    }

    .text-error {
        color: $danger !important;
    }

    h1 {
        margin-bottom: 24px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 18px;
        color: $text-dark;
    }

    p {
        line-height: 26px;
        padding: 0px 25%;
        text-align: justify;
        font-weight: 300;
    }

}
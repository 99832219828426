.partners {
    @extend .products;

    .partner-photo-wrapper {
        width: 400px;
        height: 200px;
        background-color: #f5f5f5;
        margin-bottom: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .helper {
        font-size: 12px;
    }
}
.aside {
    height: 100vh;
    background-color: white;
    width: 80px;
    overflow-x: hidden;
    position: fixed;
    z-index: 999;
    transition: all .250s ease-in-out !important;

    .aside-logo-wrapper {
        margin-top: 24px;
        width: 42px;
        margin-left: 20px;
        justify-content: center;
        overflow: hidden;
        margin-bottom: 24px;
    }

    .menu-section {
        margin-bottom: 24px;
        margin-left: 24px;
        min-width: 80%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
            color: $text-dark3;
        }
        
        button {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;

            p {
                margin: 0px;
                margin-left: 12px;
                color: #9899ac;
                visibility: hidden;
            }
        }

        svg {
            color: $text-dark3;
            width: 20px;
        }

        .sub-menu-invisible {
            transition: all .650s ease-in-out;
            display: none;
            flex-direction: column;
            overflow: hidden;
            opacity: 0;
            width: 100%;
            margin-top: 16px;
            margin-left: 8px;
            height: 0px;

            .invisible-item {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 12px;

                button {
                    text-transform: uppercase;
                    font-size: 10px;
                    letter-spacing: 1.2px;
                    color: $text-dark2;
                }

                span {
                    margin-right: 8px;
                    font-size: 12px;
                    color: $text-dark2;
                }
            }
            
        }

    }
}

.aside:hover {
    width: 260px;

    .aside-logo-wrapper {
        margin-top: 24px;
        width: 42px;
        margin-left: 20px;
        justify-content: center;
        overflow: visible;
    }

    .menu-section {
        button {

            p {
                margin: 0px;
                margin-left: 12px;
                margin-top: 4px;
                color: $text-dark2;
                visibility: visible;
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 1.2px;
            }
        }

    }
}

.aside:hover ~ .right-container {
    margin: 20px 42px 0px 300px;
}

.menu-section:hover {
    .sub-menu-invisible {
        display: flex;
        flex-direction: column;
        visibility: visible;
        opacity: 1;
        display: flex;
        height: fit-content;
    }
}
// .menu-btn-main:hover ~ .sub-menu-invisible {
//         display: flex;
//         flex-direction: column;
//         visibility: visible;
//         opacity: 1;
//         display: flex;
//         height: fit-content;
// }
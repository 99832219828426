.right-floating-aside-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 4000;

    .dismiss-bg {
        background-color: rgba(0,0,0,.05);
        width: 100vw;
        height: 100vh;
    }

    .right-content {
        background-color: white;
        height: 100vh;
        width: 30vw;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 40000;
        animation: right-content-slide .450s ease-in-out;

        .close {
            position: absolute;
            top: 54px;
            left: 32px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid $primary;
                padding: 4px;
                border-radius: 4px;
                color: $primary;

                span {
                    color: $primary;
                }
            }
        }
        
        .children-content {
            height: 100%;
            width: 100%;
        }
    }
}

@keyframes right-content-slide {
    from {
        transform: translateX(100vw)
    }
    to {
        transform: translateX(0vw);
    }
}
$accent: #7239EA;
$accent-inactive: #7267f08a;
$primary: #039EF7;
$primary-inactive: #039ef78a;
$alert: #FFC702;
$success: #50CD89;
$danger: #F1416D;
$background-light: #F5F8FA;
$background-accent: #CBD4F4;
$background-success: #CCF0F4;
$background-danger: #F7D9E3;

$text-dark: #181C32;
$text-dark2: #5e5873;
$text-dark3: #625f6e;
$text-dark4: #25252527;

$icon-inactive: #6e6b7b;

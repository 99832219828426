.client {
    @extend .products;
    position: relative;
    transition: all .250s ease-in-out;

    .row {
        transition: all .250s ease-in-out;
        height: fit-content;
    }

    #expand-client-data {
        display: none;
    }

    #expand-holders-data {
        display: none;
    }

    .expand-btn {
        transition: all .250s ease-in-out;
    }

    .expand-holders-btn {
        transition: all .250s ease-in-out;
    }


   #expand-holders-data:checked ~ .row {
        height: 84px !important;
        overflow: hidden;
    
            .expand-holders-btn {
                transform: rotateZ(-90deg)
            }
        }
        .expand-holders-btn {
            position: absolute;
            height: fit-content;
            width: fit-content;
            right: 24px;
            top: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            border-radius: 4px;
    
            span {
                color: $primary;
                font-size: 32px;
            }
        }
    
    #expand-client-data:checked ~ .row {
    height: 84px !important;
    overflow: hidden;

        .expand-btn {
            transform: rotateZ(-90deg)
        }
    }


    .expand-btn {
        position: absolute;
        height: fit-content;
        width: fit-content;
        right: 24px;
        top: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;

        span {
            color: $primary;
            font-size: 32px;
        }
    }
}

@import './colors.scss';

.internal-layout {
    min-height: 100vh;
    background-color: #f8f8f8;
    display: flex;

    .right-container {
        position: relative !important;
        flex: 1;
        min-height: 100vh;
        margin: 20px 42px 0px 120px;
        transition: all .450s ease-in-out !important;
        padding-bottom: 64px;

        .header {
            position: sticky;
            top:0;
            left: 0;
            height: 62px;
            width: 100%;
            background-color: white;
            border-radius: 8px;
            z-index: 999;
        }
    }
}
.profile {
    background-color:white;
    padding: 64px;
    border-radius: 8px;

    h1 {
        margin-bottom: 24px;
        font-size: 22px;
        color: $accent; 
    }

    .avatar-wrapper {
        margin-top: 24px;
        display: flex;
        button {
            background-color: transparent;
            border: none;
            color: $accent;
        }
        img {
            border-radius: 8px;
            max-width: 120px;
            max-height: 120px;
        }
    }
    
    .profile-input-wrapper {
        max-width: 400px;
        width: 100%;

        label {
            margin-top:  24px;
        }
    }
}
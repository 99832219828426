.dashboard {
    @extend .products;
    .apexcharts-toolbar {
        display: none;
    }

    .first-statistic {
        margin-right: 12px;
    }

    .statistics {
        
        .item-wrapper {
            display: flex;
            align-items: center;

            .item-icon {
                span {
                    border-radius: 100px;
                    padding: 8px;
                    background-color: aqua;
                }
                margin-right: 8px;
            }

            .item-text {
                span {
                    font-size: 12px;
                    margin-top: 16px;
                }
            }
        }
    }

.total-contracts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
        
    p {
        margin: 0px;
        padding: 0px;
    }

    .title-wrapper {

        padding: 24px;

        h5 {
            color: #5E5873;
        }

        p {
            margin-top: 8px;
            font-size: 12px;
            color: #6E6B7B;
            }
        }

        .medal-wrapper {
            position: absolute;
            right: 42px;
            top: 0px;
            height: fit-content;

            svg {
                width: 100%;
            }

            @media screen and (max-width: 1400px) {
                svg {
                    width: 50px;
                    height: fit-content;
                }
            }

        }

        .btn-wrapper {
            padding: 24px;

            p {
                margin-bottom: 16px;
                color: #5E5873;
                font-size: 24px;
                font-weight: 600;
            }

            span {
                font-size: 9px;
                color: black;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .card-finance-income {
        @extend .card-bg;
        background-color: white;
        width: 100%;
        height: fit-content;
        margin-top: 24px;


        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .basic-input {
                select {
                    border-radius: 0px;
                    min-width: 80px;
                    height: 28px !important;
                    color: $primary;
                    font-size: 10px;
                    border: $primary 1px solid;

                    @media screen and (max-width: 1500px) {
                        min-width: 60px;
                        font-size: 8px;
                    }

                }

                .select-wrapper::after {
                    content: "▼";
                    font-size: 10px;
                    top: 10px;
                    color: $primary;
                    right: 10px;
                    position: absolute;
                }
            }

            .title {
                color: #5E5873;
            }
    
            .subtitle {
                margin-top: 8px;
                font-size: 1rem;
                font-weight: 600;
                color: #6E6B7B;
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                    
                    margin-left: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .percent {
                    font-size: 10px;
                    font-weight: 300;
                    margin: 0px;
                    padding: 0px;
                    margin-left: 2px;
                }
            }
        }

        .trend-wrapper {
            padding-top: 8px;
            background-color: #f3fcf3;
        }

        .chart-time-wrapper {
            padding: 0px 24px;
            display: flex;
            justify-content: space-between;

            .chart-time-inactive {
                button {
                    border: 1px solid #ada6a6;
                    background-color: white;
                    color: #ada6a6;
                    padding: 4px 8px;
                    border-radius: 2px;
                    font-size: 10px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .chart-time-active {
               

                button {
                    border: 1px solid #7367F0;
                    background-color: rgb(238, 237, 253);
                    color: #7367F0;
                    padding: 4px 8px;
                    border-radius: 2px;
                    font-size: 10px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

        }
        

        .custom-chart {
            tspan {
                display: none;
            }
        }

       
        
    }

    .card-finance-expense {
        @extend .card-bg;
        background-color: white;
        width: 100%;
        height: fit-content;
        margin-top: 24px;

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .basic-input {
                select {
                    border-radius: 0px;
                    width: 80px;
                    height: 28px !important;
                    color: $primary;
                    font-size: 10px;
                    border: $primary 1px solid;
                }

                .select-wrapper::after {
                    content: "▼";
                    font-size: 10px;
                    top: 10px;
                    color: $primary;
                    right: 10px;
                    position: absolute;
                }
            }

            .title {
                color: #5E5873;
            }
    
            .subtitle {
                margin-top: 8px;
                font-size: 24px;
                font-weight: 600;
                color: #6E6B7B;
                }
        }

        .custom-chart {
            tspan {
                display: none;
            }
        }
    }

    .card-contract-activities {
        @extend .card-bg;
        background-color: white;
        width: 100%;
        height: 100%;
        background-color: #7367F0;
        min-height: 261px;
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        .total-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: white;
            padding: 9px 16px;
            border-radius: 4px;
            :nth-child(1) {
                text-transform: uppercase;
                margin-bottom: 4px;
                text-align: center;
                font-size: 10px;
                color: #6200EE;
            }

            :nth-child(2) {
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #212121;
            }
        }

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .title {
                color: white;
            }
        }

        .content-wrapper {
            padding: 0px 24px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;


            button {
                background-color: transparent;
                border: none;
                margin: 12px 0px;
                display: flex;
                flex-direction: column;

                p {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 10px;
                    color: white;
                }

                .track-wrapper {
                    width: 100%;
                    height: 12px;
                    border-radius: 24px;
                    background-color: #f5f5f5;
                    margin-top: 8px;

                    .track-active {
                        background-color: rgb(97, 212, 97);
                        width: 0%;
                        height: 12px;
                        border-radius: 24px;
                    }

                    .track-inactive {
                        background-color: #FAA527;
                        // width: 25%;
                        width: 0%;
                        height: 12px;
                        border-radius: 24px;
                    }
                }

            }

        }
    }


    .card-contract-activities-primary {
        @extend .card-bg;
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 261px;
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .title {
                color: #5E5873;
            }
        }

        .content-wrapper {
            padding: 0px 24px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .text-number-alert {
                font-weight: 600;
                font-size: 72px;
                color: $alert;
                width: fit-content;
                text-align: center;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

            }


            button {
                background-color: transparent;
                border: none;
                margin: 12px 0px;
                display: flex;
                flex-direction: column;

                p {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 10px;
                    color: white;
                }

                .track-wrapper {
                    width: 100%;
                    height: 12px;
                    border-radius: 24px;
                    background-color: #f5f5f5;
                    margin-top: 8px;

                    .track-active {
                        background-color: rgb(97, 212, 97);
                        width: 50%;
                        height: 12px;
                        border-radius: 24px;
                    }

                    .track-inactive {
                        background-color: #FAA527;
                        // width: 25%;
                        height: 12px;
                        border-radius: 24px;
                    }
                }

            }

            .open-new-tab {
                background-color: $primary;
                color: white;
                padding: 8px 16px;
                border-radius: 4px;
            }

        }
    }

    .contract-reports-cards {
        @extend .card-bg;
        background-color: white;
        width: 100%;
        min-height: 350px;
        margin-top: 24px;
        border-radius: 6px;

        .apexcharts-toolbar {
            display: none;
        }

        .allow-modifications {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;

            .active {
                height: 28px;
                margin-bottom: 12px;
                border: 1px solid $primary;
                background-color: $primary;
                color: white;
                font-size: 10px;
                padding: 4px 16px;
            }

            .inactive {
                height: 28px;
                margin-bottom: 12px;
                border: 1px solid $primary;
                background-color: white;
                color: $primary;
                font-size: 10px;
                padding: 4px 16px;
            }
        }

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .title {
                color: #5E5873;
            }

            .basic-input {
                select {
                    border-radius: 0px;
                    min-width: 80px;
                    height: 28px !important;
                    color: $primary;
                    font-size: 10px;
                    border: $primary 1px solid;

                }

                .select-wrapper::after {
                    content: "▼";
                    font-size: 10px;
                    top: 10px;
                    color: $primary;
                    right: 10px;
                    position: absolute;
                }
            }
        }
    }

    .defaulter {
        @extend .card-bg;
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .title {
                color: #5E5873;
            }
        }

        .dashboard-card-content {
            flex: 1;
        }

        .dashboard-card-footer {
            border-top: #e5e5e5 1px solid;
            padding: 18px;
            display: flex;
            justify-content: center;

            button {
                background-color: transparent;
                border: none;
                font-weight: 600;
                color: $primary;
            }
        }
    }

    .desactivated-user {
        @extend .card-bg;
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            .title {
                color: #5E5873;
            }
        }

        .dashboard-card-content {
            flex: 1;
        }

        .dashboard-card-footer {
            border-top: #e5e5e5 1px solid;
            padding: 18px;
            display: flex;
            justify-content: center;

            button {
                background-color: transparent;
                border: none;
                font-weight: 600;
                color: $primary;
            }
        }
    }
}

.card-overlay {
    background-color: rgba($color: #ffff, $alpha: 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

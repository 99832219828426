.config {
    padding: 64px;

    section {
        background-color: #fbfbfb;
        padding: 32px;
        border-radius: 8px;
    }

    .allow-modifications {
        margin-bottom: 24px;
        input {
            margin-right: 8px;
        }
    }

    h1 {
        font-size: 22px;
        margin-bottom: 24px;
        color: $text-dark;
    }

    label {
        margin-bottom: 24px;
    }
}
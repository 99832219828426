@import './colors.scss';
.header {

    nav {
        padding: 0px 24px;

        .menu-link {
            cursor: pointer;
            padding: 0px 32px;
            span {
                color: $icon-inactive;
            }
        }
        .avatar-wrapper {
            display: flex;
            align-items: center;
            position: relative;

            .online {
                position: absolute;
                bottom: 2px;
                right: 0px;
                width: 8px;
                height: 8px;
                border-radius: 8px;
                background-color: greenyellow;
                z-index: 999;
            }

            p {
                color: $text-dark2;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
            }

            span {
                color: $text-dark3;
                font-weight: 300;
            }

            .material-icons-round {
                color: $icon-inactive;
            }

            img {
                margin-left: 16px;
                max-width: 40px;
                border-radius: 100px;
            }


            .avatar-menu-hidden {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 56px;
                right: -24px;
                background-color: white;
                padding: 24px 32px;
                width: 130%;
                border-radius: 8px;
                transition: all .250s ease-in-out;

                .avatar-menu-hidden-item-wrapper {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 12px 0px;
                    cursor: pointer;

                    span {
                        margin-right: 8px;
                    }
                }

            }
        }

        .avatar-wrapper:hover {
            .avatar-menu-hidden {
                visibility: visible;
                opacity: 1;
            }
        }
    }
  
}
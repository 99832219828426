@import './users.scss';

.products {
    padding-bottom: 64px;

    section {
        padding: 32px;
        border-radius: 8px;
    }

    h1 {
        font-size: 22px;
        margin-bottom: 24px;
        color: $text-dark;
    }

    label {
        margin-bottom: 24px;
    }

    .benefits-wrapper {
        width: fit-content;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 8px;
        button {
            background-color: transparent;
            border-radius: 100px;
            padding: 4px 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            color: $primary;
            border: 1px solid $primary;
            display: flex;
            align-items: center;
            font-weight: 300;
            font-size: 12px;

            span {
                font-size: 14px;
                color: #c4c4c4;
                margin-left: 4px;
            }
        }
    }

    .optionals {

        p {
            color: $text-dark2;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 1.2px;
        }

        .check-wrapper {
            margin-top: 18px;
            display: flex;
            align-items: center;
            p {
                margin-left: 8px;
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 0;
                color: $text-dark;
                font-weight: 400;
            }
        }
    }
}
@import './colors.scss';

.login {
    .left-content {
        background-color: $accent;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-content {    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .form-wrapper {
            max-width: 400px;
            width: 100%;

            label {
                margin-bottom: 1.68rem;
                position: relative;

            }

            i {
                position: absolute;
                right: 16px;
                top: 36px;
                z-index: 999;
                cursor: pointer;
            }

            #login-password-input {
                padding-right: 54px;
            }

            .forgot-password {
                text-align: end;
                font-size: 0.8rem;
                text-transform: uppercase;
                font-size: 0.68rem;
                font-weight: 600;
                color: #252525;
            }

            button {
                height: 40px;
                width: 100%;
                margin-top: 24px;
                background-color: #00c16e;
                border: none;
                color: white;
                border-radius: 6px;
            }
        }

        .back-to-login  {

            i {
                color: #5390D9;
            }
            a {
                margin-left: 8px;
                color: #5390D9;
            }
        }
    }
}
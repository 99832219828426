@import './colors.scss';
@import './reset.scss';
@import './components.scss';
@import './login.scss';
@import './internal-layout.scss';
@import './aside.scss';
@import './header.scss';
@import './loading.scss';
@import './profile.scss';
@import './config.scss';
@import './users.scss';
@import './right-floating-aside.scss';
@import './new-admin-form.scss';
@import './products.scss';
@import './sellers.scss';
@import './new-seller.scss';
@import './client.scss';
@import './contracts.scss';
@import './holders.scss';
@import './view-partners.scss';
@import './partners.scss';
@import './dashboard.scss';


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.material-icons-round {
    color: $icon-inactive;
}

@font-face {
    font-family:'card-font';
    src:url("../assets/fonts/nokio-slab-regular.otf");
}

body {
    background-color: white;
    // font-family: 'Poppins', sans-serif;
    font-family: 'Inter', sans-serif;
}

a {
    cursor: pointer;
}